.controls {
  display: flex;
  border: 1px solid #ccc;
  border-top: 0;
  padding: 10px;
}

.controls-right {
  margin-left: auto;
}

.state {
  margin: 10px 0;
  font-family: monospace;
}

.state-title {
  color: #999;
  text-transform: uppercase;
}

.editor-input .ql-editor {
  padding: 0 0.25em;
  line-height: 20px;
  font-size: 14px;
}

.editor-input .ql-editor .mention {
  color: var(--blue-a11);
  background-color: var(--blue-a3);
  border-radius: 6px;
  padding: 0px 2px;
  padding-bottom: 1px;
  margin-right: 2px;
  user-select: all;
}

.editor-input .ql-mention-list-container {
  border-radius: var(--radius-4);
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
  background-color: var(--base-menu-bg);
  box-shadow: var(--shadow-5);
}

.editor-input .ql-mention-list-container .ql-mention-list-item.selected {
  color: var(--blue-a11);
  background-color: var(--blue-a3);
  border-radius: 6px;
  padding: 0px 4px;
  padding-bottom: 1px;
  margin-right: 2px;
  user-select: all;
  cursor: pointer;
}

.editor-input .ql-mention-list-container .ql-mention-list-item.selected:hover {
  color: var(--blue-a12);
  background-color: var(--blue-a4);
}

.editor-input .ql-mention-list-container .ql-mention-list-item:hover {
  color: var(--gray-a12);
  background-color: var(--gray-a4);
}

.editor-input * {
  font-family: var(--default-font-family);
}

.editor-input .ql-container {
  border: 0;
  margin-top: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.25em;
  border-top: 1px solid #88888822;
}

.editor-input .ql-toolbar {
  border: 0;
  padding: 0;
}

.editor-input .ql-editor.ql-blank::before {
  left: 0.25em;
  right: 0.25em;
  font-style: normal;
}

.editor-input .ql-disabled .ql-editor.ql-blank::before {
  left: 0.0em;
  right: 0.0em;
  font-style: normal;
}

.editor-input .ql-snow.ql-toolbar button, .editor-input .ql-snow .ql-toolbar button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-block;
  float: left;
  height: 20px;
  padding: 1px 3px;
  width: 26px;
  opacity: 0.5;
  transition: all 200ms;
}

.editor-input .ql-toolbar button.ql-active, .editor-input .ql-toolbar button:hover {
  opacity: 1;
  color: #3E63DD;
}

.editor-input .ql-toolbar button.ql-active .ql-stroke, .editor-input .ql-toolbar button:hover .ql-stroke {
  stroke: #3E63DD;
}

.editor-input .ql-toolbar button.ql-active .ql-fill, .editor-input .ql-toolbar button:hover .ql-fill {
  fill: #3E63DD;
}

/* Dark mode */
.dark {
  .editor-input .ql-toolbar button.ql-active, .editor-input .ql-toolbar button:hover {
    opacity: 1;
    color: #FFFFFF;
  }
  
  .editor-input .ql-toolbar button.ql-active .ql-stroke, .editor-input .ql-toolbar button:hover .ql-stroke {
    stroke: #FFFFFF;
  }
  
  .editor-input .ql-toolbar button.ql-active .ql-fill, .editor-input .ql-toolbar button:hover .ql-fill {
    fill: #FFFFFF;
  }

  .editor-input .ql-editor.ql-blank::before {
    color: #FFFFFF88;
  }
}

.editor-input .ql-toolbar {
  margin-left: -3px;
  margin-top: -4px;
  margin-bottom: -2px;
}


.editor-input .ql-toolbar button {
  opacity: 0.5;
}

.editor-input.has-focus .ql-toolbar button, .editor-input:hover .ql-toolbar button, .editor-input .ql-toolbar button.ql-active {
  opacity: 1;
}

.editor-input.is-disabled .ql-toolbar {
  display: none;
}
.editor-input.is-disabled .ql-container {
  margin: 0 !important;
  padding: 0 !important;
}
.editor-input.is-disabled .ql-editor {
  margin: 0 !important;
  padding: 0 !important;
}

